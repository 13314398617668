import { Col, Collapse, Divider, Icon, List, Modal } from "antd";
import React, { Fragment, useState } from "react";
import Facebook from "../../../shared/assets/images/Facebook.svg";
import Youtube from "../../../shared/assets/images/YouTube.svg";
const { Panel } = Collapse;

export default () => {
  const [selected, setSelected] = useState(false);
  return (
    <>
      <Collapse
        expandIconPosition={"right"}
        bordered={false}
        expandIcon={({ isActive }) => (
          <Icon type="caret-right" rotate={isActive ? 90 : 0} />
        )}
      >
        <Panel header="L'espace club" key="1" className="contact_info_item">
          <div className="contact_info_item_content">
            <div
              className="contact_info_item_content_link"
              onClick={() => {
                return setSelected(true);
              }}
            >
              Mes réseaux sociaux
            </div>
          </div>
        </Panel>
      </Collapse>
      <Modal
        className="form-modal"
        title="Nos réseaux sociaux"
        visible={selected}
        width={800}
        onOk={() => setSelected(false)}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={() => setSelected(false)}
      >
        <Fragment>
          Imprégnez vous de l'univers Autoplus:
          <List>
            <List.Item className="listStyle">
              <Col span={4}>
                <img src={Facebook} alt={"Facebook"} />
              </Col>
              <Col span={20}>
                <a
                  style={{ cursor: "pointer" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://web.facebook.com/maghrebaccessoires"
                >
                  Retrouvez tout l'univers sur Facebook, photos, communautés,
                  annonces de promotions exclusives..
                </a>
              </Col>
            </List.Item>
          </List>
          <Divider plain />
          <List>
            <List.Item className="listStyle">
              <Col span={4}>
                <img src={Youtube} alt={"Youtube"} />
              </Col>
              <Col span={20}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.youtube.com/watch?v=hDgcVfUAs2Q"
                >
                  Retrouvez nos videos dans notre chaine Youtube: Entretien,
                  réparation, pièces techniques, montage..
                </a>
              </Col>
            </List.Item>
          </List>
        </Fragment>
      </Modal>
    </>
  );
};
