import { Modal } from "antd";
import React from "react";

export default ({ selectedDelivery, setSelectedDelivery }) => {
  return (
    <Modal
      className="form-modal"
      title="LIVRAISON – DELAI"
      visible={selectedDelivery}
      width={800}
      onOk={() => setSelectedDelivery(false)}
      cancelButtonProps={{ style: { display: "none" } }}
      onCancel={() => setSelectedDelivery(false)}
    >
      <p>
        Le Vendeur s'engage à traiter la Commande dans le délai de 2 jours
        ouvrables à compter du jour suivant la validation de la Commande, à
        l'exception de certaines catégories de produits non gérées en stock dont
        les articles personnalisés. Le dépassement de ce délai ne saurait
        entraîner une quelconque pénalité de retard.
      </p>
      <p>
        Les délais de livraison indiqués lors de la Commande sont purement
        indicatifs et dépendent des possibilités d'approvisionnement et de
        transport.
      </p>
      <p>
        La livraison s'effectue uniquement au Maroc. La livraison est effectuée
        à l'adresse indiquée par l'Acheteur lors de sa Commande.
      </p>
      <ol style={{ listStyleType: "lower-alpha" }}>
        <li style={{ fontWeight: "bold", textDecorationLine: "Underline" }}>
          Modalités de commande
        </li>
        <br />
        <p>
          Le montant de commande est indiqué dans le Panier, le récapitulatif de
          la commande est sur la facture. Les Produits commandés par le Client
          seront livrés exclusivement au Maroc. Les produits seront livrés dans
          les délais indiqués lors du récapitulatif de commande en fonction de
          l'option de livraison choisie, à l'adresse indiquée par le Client lors
          de sa commande sur le Site ou dans le centre Autoplus choisi par le
          Client lors de sa commande, si ce dernier a choisi cette option.
        </p>
        <p>
          Les délais indiqués sur le panier de commande sont des délais moyens
          habituels correspondant aux délais d'acheminement et aux délais de
          traitement d'expédition. Dans le cas d'une livraison en magasin, une
          fois la commande validée par Autoplus
        </p>
        <p>
          Autoplus ne saurait être mis en cause et tenu pour responsable des
          conséquences de tout événement échappant à sa volonté, notamment les
          cas de force majeure, qui retarderait ou empêcherait la livraison des
          produits commandés.
        </p>
        <p>
          Autoplus fera ses meilleurs efforts pour livrer les Produits en une
          fois. Toutefois, le Client est informé que les produits pourront être
          livrés en plusieurs fois. En tout état de cause, en cas de commande
          multiple avec délais d'expédition différents, le délai d'expédition de
          la commande est basé sur le délai le plus long.
        </p>
        <li style={{ fontWeight: "bold", textDecorationLine: "Underline" }}>
          Livraison à domicile
        </li>
        <br />
        <p>
          Le Client s'engage à transmettre, lors de la commande, toutes les
          informations nécessaires à la livraison afin d'en garantir la bonne
          exécution, et notamment un numéro de téléphone auquel le transporteur
          peut le joindre dans la journée. Lors de la livraison, le Client doit
          pouvoir, si cela lui est demandé, présenter une pièce d'identité.
        </p>
        <p>
          Si le Client opte pour la livraison à domicile et en cas d'absence le
          jour de la livraison, le transporteur laissera un avis de passage. Le
          Client se conformera aux instructions du transporteur après le premier
          avis de passage, afin d'organiser avec celui-ci la réception ou
          l'enlèvement des produits.
        </p>
        <p>
          A défaut de réception des produits ou d'enlèvement des Produits en
          agence transporteur dans un délai communiqué par le transporteur et
          suivant son premier avis de passage, les produits seront
          automatiquement retournés à la Société.
        </p>
        <p>
          En cas de difficultés rencontrées avec le transporteur, le Client
          devra, dans les mêmes délais, en informer la Société par message
          électronique.
          <br />
          Le Client est tenu de vérifier l'état des Produits livrés et de
          s'assurer de leur conformité avec la commande.
        </p>
        <p>
          En cas de problème (colis fracturé, produits manquants, etc), le
          Client portera toutes réserves correspondantes aux observations
          constatées sur la feuille d'émargement, fera signer le transporteur et
          conservera un exemplaire du document.
        </p>
        <p>
          Les réserves doivent être notifiées au transporteur et à Autoplus par
          lettre recommandée avec accusé de réception dans les trois (3) jours
          suivant la réception du produit. En cas d'avarie, le Client doit
          refuser le produit endommagé au livreur et indiquer « refus pour
          avarie » sur la feuille d'émargement, faute de quoi tout recours
          contre le transporteur serait impossible.
        </p>
        <p>
          Passé ce délai de trois (3) jours, et à défaut d'avoir respecté ces
          formalités, les Produits seront réputés ne pas présenter d'avarie(s),
          ni d'anomalie(s) liée(s) au transport et aucune réclamation ne pourra
          être valablement acceptée par la Société.
        </p>
        <p>
          Par la signature du récépissé de livraison sans émettre de réserves
          précises, et l'acceptation des produits, le Client reconnaît avec reçu
          la marchandise sans aucune d'avarie(s), ni d'anomalie(s) liée(s) au
          transport, dans un état lui donnant toute satisfaction.
        </p>
        <p>
          En cas de livraison non conforme à la commande en qualité ou en
          quantité, le Client doit en informer Autoplus en indiquant la
          référence de la commande, en appelant nos conseillers téléphoniques au
          téléphone : 05 22 51 61 61, du lundi au vendredi de 09h00 à 18h00, ou
          par le biais d'un courriel adressé à :
          <i style={{ fontWeight: "bold", color: "blue" }}>
            clients@maghrebaccessoires.com
          </i>
        </p>
      </ol>
    </Modal>
  );
};
