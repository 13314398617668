import gql from "graphql-tag";

export const CART_ITEMS = gql`
  query CartItems(
    $where: CartItemWhereInput
    $isIn: [CartItemWhereInput]
    $skip: Int
    $like: CartItemWhereInput
    $take: Int
    $gt: CartItemWhereInput
    $lt: CartItemWhereInput
  ) {
    cartItems(
      where: $where
      isIn: $isIn
      skip: $skip
      take: $take
      like: $like
      gt: $gt
      lt: $lt
    ) {
      data {
        id
        createdDate
        cart {
          id
          account {
            id
            title
          }

          amount
        }
        quantity

        accountDestination {
          id
          title
        }
        item {
          id
          code
          name
          thumbnail
        }
        status
        deleted
      }
      count
    }
  }
`;

export const CART_ITEMS_COUNT = gql`
  query CartItemsCount {
    cartItemsCount
  }
`;

export const STATISTICS = gql`
  query Statistics($where: CartItemWhereInput) {
    statistics(where: $where) {
      id
      total
      saved
    }
  }
`;

export const CART_ITEM = gql`
  query CartItem($where: CartItemWhereInput) {
    cartItem(where: $where) {
      id
      createdDate
      cart {
        id
        account {
          id
          title
        }
        amount
      }
      quantity
      item {
        id
        code
      }
      status
      deleted
    }
  }
`;

export default {
  CART_ITEMS,
  CART_ITEM,
  STATISTICS,
};
