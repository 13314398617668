import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { AutoComplete, Button, Icon } from "antd";
import React, { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import Highlighter from "react-highlight-words";
import { TabContext } from "../../../../../Context/Tab";
import { ADD_RECHERCHE } from "../../../../../shared/graphql/mutations/recherche";
import { ManufacturersQueries } from "../../../../../shared/graphql/queries";
import { SCREENS, TABS_ACTIONS } from "../../../../../utils/constants";
import { format } from "../../../../../utils/helpers";
import VINUploadModal from "./VINUploadModal";
const { VEHICLES } = ManufacturersQueries;

const { Option, OptGroup } = AutoComplete;
export default ({ active }) => {
  const { dispatch } = useContext(TabContext);

  //WAUZZZF57NA002248 AUDI A5

  const [vin, setVin] = useState("");
  useEffect(() => {
    if (!active) {
      setVin("");
    }
  }, [active]);

  const [uploadVinVisible, setUploadVinVisible] = useState(false);

  const [getVehiculesByVin, { data }] = useLazyQuery(VEHICLES);

  const [addRecherche, { dataAddRecherche, loading, error }] =
    useMutation(ADD_RECHERCHE);

  //// console.log("data", data?.vehicles.data);

  const options = data
    ? [
        <OptGroup key={"Suggestions VIN"}>
          {data?.vehicles.data.map(({ id, name, manufacturer, model }) => (
            <Option key={id} value={id}>
              <div
                className="div-vertical"
                onClick={() => {
                  // console.log("args", { id, name, manufacturer, model });
                  console.log("dispatch 6 *******");

                  console.log("send event", {
                    category: "recherche",
                    action: "vin",
                    label: `${
                      vin +
                      " (" +
                      manufacturer.name +
                      " ->" +
                      model.name +
                      " ->" +
                      name +
                      ")"
                    }`.toUpperCase(), // optional
                    value: 99, // optional, must be a number
                    //  nonInteraction: true, // optional, true/false
                    //  transport: "xhr", // optional, beacon/xhr/image
                  });
                  ReactGA.event({
                    category: "recherche",
                    action: "vin",
                    label: `${
                      vin +
                      " (" +
                      manufacturer.name +
                      " ->" +
                      model.name +
                      " ->" +
                      name +
                      ")"
                    }`.toUpperCase(), // optional
                    value: 99, // optional, must be a number
                    //  nonInteraction: true, // optional, true/false
                    //  transport: "xhr", // optional, beacon/xhr/image
                  });

                  addRecherche({
                    variables: {
                      rechercheInput: {
                        vin,
                        type: "vin",
                        motorisation: {
                          id,
                          designation: name,
                          modele: {
                            id: model.id,
                            designation: model.name,
                            marque: {
                              id: manufacturer.id,
                              designation: manufacturer.name,
                            },
                          },
                        },
                      },
                    },
                  });

                  dispatch({
                    type: TABS_ACTIONS.HISTORY_PUSH,
                    payload: {
                      screen: SCREENS.ASSEMBLYGROUPS,
                      props: {
                        search: {
                          linkingTarget: id,
                          manufacturer: manufacturer.id,
                          manufacturerBean: manufacturer,
                          model: model.id,
                          modelBean: model,
                          motorizationBean: { id, name, model, manufacturer },
                        },
                      },
                    },
                  });
                }}
              >
                <div className="div-horizontal center">
                  <div
                    className="div-horizontal center center-justified"
                    style={{ width: 80 }}
                  >
                    <object
                      style={{
                        marginRight: 10,

                        height: 20,
                      }}
                      key={model.id}
                      data={`https://media.carparts-cat.com/webdata2/kmodthumb/${model.id}.png`}
                      type="image/png"
                    >
                      <img
                        style={{ height: 20 }}
                        src="https://www.freeiconspng.com/uploads/no-image-icon-13.png"
                        alt="Modèle"
                      />
                    </object>
                  </div>
                  <Highlighter
                    highlightClassName="YourHighlightClass"
                    highlightTag={({ children, highlightIndex }) => (
                      <strong
                        className="highlighted-text"
                        style={{ color: "red" }}
                      >
                        {children}
                      </strong>
                    )}
                    searchWords={[format(vin)]}
                    autoEscape={true}
                    textToHighlight={
                      manufacturer.name + " " + model.name + " " + name
                    }
                  />
                </div>
              </div>
            </Option>
          ))}
        </OptGroup>,
      ]
    : [];

  return (
    <div className="div-horizontal">
      <AutoComplete
        placement={"bottomCenter"}
        dropdownClassName="recherche-autocomplete-dropdown"
        dropdownMatchSelectWidth={false}
        size="medium"
        value={vin}
        style={{ width: "100%" }}
        dataSource={options}
        placeholder="Cherchez un VIN"
        optionLabelProp="value"
        onChange={(value) => {
          // console.log("vin", value);

          setVin(value);
          if (value.length === 17)
            getVehiculesByVin({ variables: { where: { vin: value } } });
        }}
      />
      <Button
        type="primary"
        className="div-horizontal center center-justified"
        style={{ marginLeft: 5, padding: 5 }}
        onClick={() => {
          setUploadVinVisible(true);
        }}
      >
        <Icon style={{ fontSize: 22 }} type="upload" />
      </Button>

      <VINUploadModal
        visible={uploadVinVisible}
        setVisible={setUploadVinVisible}
      />
    </div>
  );
};
