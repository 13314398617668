import { Col, Divider, Modal, Row } from "antd";
import React from "react";
import Excellency from "../../../../../shared/assets/images/Excellency.svg";
import Innovation from "../../../../../shared/assets/images/Innovation.svg";
import Respect from "../../../../../shared/assets/images/Respect.svg";
import Service from "../../../../../shared/assets/images/Service.svg";
import TeamSpirit from "../../../../../shared/assets/images/TeamSpirit.svg";
export default ({ selected, setSelected }) => {
  return (
    <Modal
      className="form-modal"
      title="Nos valeurs"
      visible={selected}
      width={800}
      onOk={() => setSelected(false)}
      cancelButtonProps={{ style: { display: "none" } }}
      onCancel={() => setSelected(false)}
    >
      <p>
        Si depuis 1948 AutoPlus maintient son engagement pour une mobilité
        automobile sécurisée, c'est parce que nos équipes incarnent des qualités
        humaines autour d'un objectif commun : Créer les meilleures expériences
        possibles pour nos clients. <br />
        <br /> Nos valeurs déﬁnissent qui nous sommes. Elles sont inscrites dans
        l'ADN d'Auto Plus. Elles inﬂuencent en permanence la façon dont on
        travaille ensemble et guident nos actions et comportements.
      </p>
      <Divider plain />
      <Row>
        <Col span={4}>
          <img src={Excellency} alt={"excellency"} />
        </Col>
        <Col span={20}>
          <h5 style={{ color: "red", fontWeight: "bold" }}>Excellence</h5>
          La quête de l'excellence, se caractérise chez Autoplus par la volonté
          de tous nos collaborateurs de se dépasser pour offrir la meilleure
          prestation à nos clients. C'est pour satisfaire nos clients que nous
          visons l'excellence dans chacune de nos actions.
        </Col>
      </Row>
      <Divider plain />
      <Row>
        <Col span={4}>
          <img src={Respect} alt={"respect"} />
        </Col>
        <Col span={20}>
          <h5 style={{ color: "purple", fontWeight: "bold" }}>Respect</h5>
          Parce que notre métier affecte de nombreuses parties prenantes, nous
          nous engageons de façon permanente à garantir à nos clients des
          produits de qualité, à prendre en considération toutes leurs attentes
          avec bienveillance, à leurs porter une écoute attentive aﬁn
          d'instaurer un lien humain et d'égalité.
        </Col>
      </Row>
      <Divider plain />
      <Row>
        <Col span={4}>
          <img src={Innovation} alt={"innovation"} />
        </Col>
        <Col span={20}>
          <h5 style={{ color: "orange", fontWeight: "bold" }}>Innovation</h5>
          Nous appelons à la créativité de tous nos collaborateurs aﬁn de
          stimuler le désir de relever les déﬁs et d'identiﬁer de nouvelles
          façon de « bien faire les choses » pour une satisfaction permanente de
          nos clients.
        </Col>
      </Row>
      <Divider plain />
      <Row>
        <Col span={4}>
          <img src={Service} alt={"service"} />
        </Col>
        <Col span={20}>
          <h5 style={{ color: "green", fontWeight: "bold" }}>Service</h5>
          Un service client de qualité est le cœur de toute organisation saine.
          Nos collaborateurs assurent un service transparent, de proximité et
          orienté client. nous mettons à disposition tous les moyens nécessaires
          pour une écoute active de leurs besoins et opinions.
        </Col>
      </Row>
      <Divider plain />
      <Row>
        <Col span={4}>
          <img src={TeamSpirit} alt={"teamSpirit"} />
        </Col>
        <Col span={20}>
          <h5 style={{ color: "pink", fontWeight: "bold" }}>Esprit d'équipe</h5>
          La vraie valeur d'une brique ne réside pas uniquementdans son unité
          mais bien dans le fait de pouvoir s'assembler et devenir une maison.
          Parce qu'ensemble nous accomplissons bien plus qu'individuellement.
          Chez <bold>Autoplus</bold>, nous travaillons en équipe pour assurer
          une meilleure disponibilité, maintenir une bonne communication et
          établir des relations saines et durables avec nos partenaires et
          clients.
        </Col>
      </Row>
    </Modal>
  );
};
