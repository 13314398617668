import { Col, Modal, Row } from "antd";
import React from "react";

export default ({ selectedOrder, setSelectedOrder }) => {
  return (
    <Modal
      className="form-modal"
      title="Etapes de suivi de la préparation de la commande"
      visible={selectedOrder}
      width={800}
      onOk={() => setSelectedOrder(false)}
      cancelButtonProps={{ style: { display: "none" } }}
      onCancel={() => setSelectedOrder(false)}
    >
      <p>
        Le Client pourra suivre l'expédition de sa commande directement dans son
        espace personnel. Les différentes étapes d'acheminement de la commande
        sont les suivantes :
      </p>
      <br />
      <Row>
        <Col span={6} style={{ fontWeight: "bold" }}>
          En attente:
        </Col>
        <Col span={18}>
          Commande en attente:&nbsp; Commande est en attente de traitement.
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={6} style={{ fontWeight: "bold" }}>
          Reçue:
        </Col>
        <Col span={18}>
          Commande Reçue:&nbsp; Commande reçu, en cours de validation
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={6} style={{ fontWeight: "bold" }}>
          En cours de traitement :
        </Col>
        <Col span={18}>
          Commande en cours de traitement: &nbsp;Commande validée, envoyée pour
          traitement logistique
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={6} style={{ fontWeight: "bold" }}>
          En cours de préparation :
        </Col>
        <Col span={18}>
          Commande en préparation :&nbsp; Commande en cours de préparation par
          l'équipe logistique.
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={6} style={{ fontWeight: "bold" }}>
          Facturée & expédiée :
        </Col>
        <Col span={18}>
          Commande Facturée : &nbsp;Commande facturée, en cours de chargement ou
          remis au comptoir agence/drive
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={6} style={{ fontWeight: "bold" }}>
          Fermée Rupture :
        </Col>
        <Col span={18}>Commande clôturée, article en rupture de stock</Col>
      </Row>
      <br />
      <Row>
        <Col span={6} style={{ fontWeight: "bold" }}>
          Clôturée :
        </Col>
        <Col span={18}>Commande clôturée</Col>
      </Row>
      <br />
      <Row>
        <Col span={6} style={{ fontWeight: "bold" }}>
          Echec :
        </Col>
        <Col span={18}>
          Commande en Echec :&nbsp; Commande non enregistrée dans notre système
          d'information. Veuillez svp contacter votre commerciale ou le support
          client : <bold>0522516161</bold>
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={6} style={{ fontWeight: "bold" }}>
          Annulée :
        </Col>
        <Col span={18}>Commande Annulée :&nbsp; Commande annulée.</Col>
      </Row>
      <br />
    </Modal>
  );
};
