import { UploadOutlined } from "@ant-design/icons";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { Button, Icon, Input, Modal, Spin, Table, Upload, message } from "antd";
import ReactGA from "react-ga4";

import axios from "axios";
import { get } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { TabContext } from "../../../../../Context/Tab";
import { apiRootUrl } from "../../../../../config";
import { ADD_RECHERCHE } from "../../../../../shared/graphql/mutations/recherche";
import { VEHICLES } from "../../../../../shared/graphql/queries/manufacturers";
import { SCREENS, TABS_ACTIONS } from "../../../../../utils/constants";
const { Search } = Input;
const VINUploadModal = ({ visible, setVisible }) => {
  const [file, setFile] = useState(null);
  const [vin, setVin] = useState("");
  const [previewImage, setPreviewImage] = useState(null); // État pour stocker l'URL de prévisualisation
  const [loading, setLoading] = useState(false);
  const { dispatch } = useContext(TabContext);

  const [getVehiculesByVin, { data, loading: loadingVehicules }] =
    useLazyQuery(VEHICLES);

  const [
    addRecherche,
    { dataAddRecherche, loading: loadingAddRecherche, error },
  ] = useMutation(ADD_RECHERCHE);

  const handleUpload = ({ file }) => {
    setFile(file);
    setPreviewImage(URL.createObjectURL(file)); // Créer une URL de prévisualisation
  };

  useEffect(() => {
    if (vin.length === 17)
      getVehiculesByVin({ variables: { where: { vin: vin } } });
  }, [vin]);

  const handleSubmit = async () => {
    if (!file) {
      message.error("Veuillez sélectionner une image d'abord.");
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await axios.post(`https://${apiRootUrl}/vin`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setLoading(false);
      message.success(`Numéro de châssis détecté : ${response.data.vin}`);
      setVin(response.data.vin);

      /*  setVisible(false); // Fermer le modal après le succès
      setFile(null); // Réinitialiser l'image
      setPreviewImage(null); // Réinitialiser la prévisualisation */
    } catch (error) {
      message.error("Erreur lors du traitement de l'image.");
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setFile(null); // Réinitialiser le fichier après la fermeture
    setPreviewImage(null); // Réinitialiser la prévisualisation
  };

  const columns = () => {
    return [
      {
        title: () => {
          return <span>Image</span>;
        },
        key: "image",
        render: ({ id, name, manufacturer, model }) => (
          <div className="div-horizontal center">
            <object
              style={{
                marginRight: 10,

                height: 20,
              }}
              key={model.id}
              data={`https://media.carparts-cat.com/webdata2/kmodthumb/${model.id}.png`}
              type="image/png"
            >
              <img
                style={{ height: 20 }}
                src="https://www.freeiconspng.com/uploads/no-image-icon-13.png"
                alt="Modèle"
              />
            </object>
          </div>
        ),
      },
      {
        title: () => {
          return <span>Véhicule</span>;
        },
        key: "id",
        render: ({ id, name, manufacturer, model }) => (
          <span>{manufacturer.name + " " + model.name + " " + name}</span>
        ),
      },
      {
        title: () => {
          return <span>Action</span>;
        },
        key: "action",
        render: ({ id, name, manufacturer, model }) => (
          <Icon
            style={{ fontSize: 20, marginLeft: 5 }}
            onClick={() => {
              // console.log("args", { id, name, manufacturer, model });
              console.log("dispatch 6 *******");

              console.log("send event", {
                category: "recherche",
                action: "vin",
                label: `${
                  vin +
                  " (" +
                  manufacturer.name +
                  " ->" +
                  model.name +
                  " ->" +
                  name +
                  ")"
                }`.toUpperCase(), // optional
                value: 99, // optional, must be a number
                //  nonInteraction: true, // optional, true/false
                //  transport: "xhr", // optional, beacon/xhr/image
              });
              ReactGA.event({
                category: "recherche",
                action: "vin",
                label: `${
                  vin +
                  " (" +
                  manufacturer.name +
                  " ->" +
                  model.name +
                  " ->" +
                  name +
                  ")"
                }`.toUpperCase(), // optional
                value: 99, // optional, must be a number
                //  nonInteraction: true, // optional, true/false
                //  transport: "xhr", // optional, beacon/xhr/image
              });

              addRecherche({
                variables: {
                  rechercheInput: {
                    vin,
                    type: "vin",
                    motorisation: {
                      id,
                      designation: name,
                      modele: {
                        id: model.id,
                        designation: model.name,
                        marque: {
                          id: manufacturer.id,
                          designation: manufacturer.name,
                        },
                      },
                    },
                  },
                },
              });

              dispatch({
                type: TABS_ACTIONS.HISTORY_PUSH,
                payload: {
                  screen: SCREENS.ASSEMBLYGROUPS,
                  props: {
                    search: {
                      linkingTarget: id,
                      manufacturer: manufacturer.id,
                      manufacturerBean: manufacturer,
                      model: model.id,
                      modelBean: model,
                      motorizationBean: { id, name, model, manufacturer },
                    },
                  },
                },
              });
            }}
            className="iconTable2"
            type="search"
          />
        ),
      },
    ];
  };

  return (
    <div>
      <Modal
        centered
        className="form-modal"
        title="Télécharger une image"
        visible={visible}
        onCancel={handleCancel}
        okText={null}
        cancelText="Annuler"
      >
        <div className="div-vertical">
          <div className="div-horizontal">
            <Upload beforeUpload={() => false} onChange={handleUpload}>
              <Button disabled={loading} icon={<UploadOutlined />}>
                Sélectionner une image
              </Button>
            </Upload>
            <Button
              onClick={handleSubmit}
              disabled={loading || !file}
              type="primary"
              style={{ marginLeft: 10 }}
              loading={loading}
            >
              Envoyer l'image
            </Button>
          </div>
          <div className="preview-cadre">
            <Spin spinning={loading}>
              <img
                src={
                  previewImage
                    ? previewImage
                    : "https://www.freeiconspng.com/uploads/no-image-icon-13.png"
                }
                alt="Prévisualisation"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </Spin>
          </div>
          <Search
            style={{ marginBottom: 10 }}
            value={vin}
            placeholder="Tapez un VIN"
            size="medium"
            onChange={(e) => setVin(e.target.value)} // Ajoutez ceci pour mettre à jour l'état à chaque frappe
            onSearch={(value) => {
              setVin(value);
            }}
            loading={loadingVehicules}
          />
          <Table
            bordered
            loading={loadingVehicules}
            dataSource={get(data, "vehicles.data", [])}
            columns={columns()}
            pagination={false}
          />
        </div>
      </Modal>
    </div>
  );
};

export default VINUploadModal;
