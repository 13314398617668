import { Spin } from "antd";
import { isEmpty, isNil } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import Loading from "../Loading";
import Empty from "../stories/Empty";

const processData = ({ data, type, meta }) => {
  let result;
  let count = 1;

  if (meta === "number") {
    console.log("meta number and type",type);
    count = data[type];
  } else if (meta === "object") {
    result = data[type];
  } else {
    result = data[type].data;
    count = data[type].count;
  }
  return { count, result };
};

const withLoader = (WrappedComponent) => {
  const Process = ({
    Loading,
    error,
    loading,
    data,
    type,
    meta,
    mapper,
    NoDataStyle,
    NoData,
    ...rest
  }) => {
    if (loading) {
      return Loading ? <Loading></Loading> : <Spin></Spin>;
    }
    if (error) {
      return (
        <div>
          <span>{error.message}</span>
        </div>
      );
    }
    if (meta === "array" && (isNil(data) || isNil(data[type])))
      return (
        <div>
          <span>ERROR</span>
        </div>
      );
    const { count, result } = processData({ data, type, meta });
    if (isEmpty(result)) {
      return NoData ? (
        <NoData
          data={result}
          count={count}
          {...rest}
          NoDataStyle={NoDataStyle}
        />
      ) : (
        <Empty description={"no data"} />
      );
    }
    return <WrappedComponent data={result} count={count} {...rest} />;
  };

  Process.propTypes = {
    Loading: PropTypes.func,
    Error: PropTypes.func,
    NoData: PropTypes.any,
    error: PropTypes.object,
    loading: PropTypes.bool,
    data: PropTypes.object,
    type: PropTypes.string.isRequired,
    meta: PropTypes.string,
    mapper: PropTypes.func,
  };

  Process.defaultProps = {
    Loading: Loading,
    mapper: (data) => data,
    meta: "array",
    error: undefined,
    loading: false,
    query: PropTypes.shape({
      data: [],
      count: 0,
    }),
  };

  return Process;
};

withLoader.propTypes = {
  WrappedComponent: PropTypes.func.isRequired,
};

withLoader.defaultProps = {};

export default withLoader;
