import { useQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { CART_ITEMS_COUNT } from "../shared/graphql/queries/cart-items";

const CartItems = ({ View, filter, options, ...props }) => {
  const query = useQuery(CART_ITEMS_COUNT);
  // console.log("render CartItems");

  useEffect(() => {
    // console.log("render CartItems useEffect");
  }, []);

  return (
    <View
      {...{
        ...props,
        ...query,
        meta: "number",
        type: "cartItemsCount",
      }}
    />
  );
};

CartItems.propTypes = {
  View: PropTypes.func.isRequired,
  filter: PropTypes.object,
  options: PropTypes.object,
};

CartItems.defaultProps = {
  filter: {},
  options: {},
};

export default CartItems;
