import { dequal } from "dequal";
import React, { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";

import OneSignal from "react-onesignal";
import { renderRoutes } from "react-router-config";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  withRouter,
} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import { TabContext } from "../Context/Tab";
import screens from "../screens";
import { Store } from "../shared/store";
import { PATHS, SCREENS, TABS_ACTIONS, TRACKING_ID } from "../utils/constants";
import { buttons } from "./widgets/Buttons";
import ModalSearch from "./widgets/ModalSearch";
import { ProductItems } from "./widgets/ProductItems";
const Screens = React.memo(
  ({
    setVisible,
    setAssemblyGroupId,
    setAssemblyGroupName,
    user,
    clearStore,
  }) => {
    const { dispatch } = useContext(TabContext);
    return (
      <Switch>
        <Route exact path={PATHS.EMPTY_PATH}>
          <Redirect to={PATHS.HOME} />
        </Route>
        {renderRoutes(
          screens({
            user,
            verticalProps: {
              items: (
                <ProductItems
                  onClick={({ id, name }) => {
                    setVisible(true);
                    setAssemblyGroupId(id);
                    setAssemblyGroupName(name);
                  }}
                ></ProductItems>
              ),
            },
            middleProps: {
              onArticlClik: () => {},
              buttons: buttons({
                clearStore,
              }),
              onClick: (reference) =>
                dispatch({
                  type: TABS_ACTIONS.HISTORY_PUSH,
                  payload: {
                    screen: SCREENS.ARTICLES,
                    props: {
                      search: {
                        reference,
                        type: "direct",
                      },
                    },
                  },
                }),

              //items: smarSearchItems({ dispatch }),
            },
          })
        )}
      </Switch>
    );
  },
  (prev, next) => dequal(prev, next)
);
const App = React.memo(
  (props) => {
    const {
      store: { clearStore, user },
    } = props;

    if (user && user.id && user.account) {
      ReactGA.initialize(TRACKING_ID, {
        gaOptions: {
          userName:
            user.username +
            " (" +
            user.account.id +
            "/" +
            user.account.title +
            ")",
          userId: user.account.id + " (" + user.account.title + ")",
        },
      });
    }
    const location = useLocation();

    const [visible, setVisible] = useState(false);
    const [assemblyGroupId, setAssemblyGroupId] = useState("");
    const [assemblyGroupName, setAssemblyGroupName] = useState("");
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
      console.log("ga useEffect", location.pathname + location.search);
      if (user && user.id && user.account) {
        ReactGA.send({
          hitType: "pageview",
          page: location.pathname,
          title: location.pathname,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
      if (user && user.id && user.account) {
        console.log("useEffect onesignal", user);
        OneSignal.init({
          appId: "cb8c623a-d0e0-47de-87b9-0c19ffc55468",
          safari_web_id:
            "web.onesignal.auto.34975c41-96f8-43c9-89c6-048b8e5234aa",

          allowLocalhostAsSecureOrigin: true,
        }).then(() => {
          console.log("init onesignal");
          setInitialized(true);
          //  OneSignal.Debug.setLogLevel("trace");

          OneSignal.Slidedown.promptPush()
            .then(() => {
              console.log(
                "prompt onesignal ok",
                OneSignal.User.PushSubscription.id
              );
              console.log(
                "prompt onesignal ok",
                OneSignal.User.PushSubscription
              );
              /* 
              OneSignal.User.addTag("username", user.username); // for sending that is inserting tag in OneSignal
              OneSignal.User.addTag("id", user.id); // for sending that is inserting tag in OneSignal
              OneSignal.User.addTag("email", user.email); // for sending that is inserting tag in OneSignal
              OneSignal.User.addTag("phone", user.phone); // for sending that is inserting tag in OneSignal
              OneSignal.User.addTag("accountId", user.account.id); // for sending that is inserting tag in OneSignal
              OneSignal.User.addTag("accountName", user.account.title); // for sending that is inserting tag in OneSignal
 */
              // console.log(OneSignal.User);
            })
            .catch((e) => {
              console.log(e);
            });
        });
      }
    }, [user]);
    if (process.env.REACT_APP_NODE_ENV === "production") {
      // Désactiver console.log
      console.log = () => {};
      // Désactiver console.warn
      console.warn = () => {};
      // Désactiver console.error
      console.error = () => {};
    }

    return (
      <div>
        <ScrollToTop
          style={{ backgroundColor: "#ed0001" }}
          smooth
          color="#fff"
        />

        <Screens
          setVisible={setVisible}
          setAssemblyGroupId={setAssemblyGroupId}
          setAssemblyGroupName={setAssemblyGroupName}
          user={user}
          clearStore={clearStore}
        ></Screens>

        <ModalSearch
          visible={visible}
          setVisible={setVisible}
          assemblyGroupId={assemblyGroupId}
          assemblyGroupName={assemblyGroupName}
        ></ModalSearch>
      </div>
    );
  },
  (prev, next) => dequal(prev, next)
);

export default Store(withRouter(App));
