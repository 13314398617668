import { ShoppingCartOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";
import "./index.css";

export default React.memo(({ count, setSkip, skip }) => {
  return (
    <Link to="/cart-items">
      <ShoppingCartOutlined />
      <span className="wishlist_count">{count > 99 ? "+99" : count}</span>
    </Link>
  );
});
