import get from "lodash/get";
import React, { Fragment } from "react";
import Articles from "./Articles";
import AssemblyGroups from "./AssemblyGroups";
import Models from "./Models";
import Vehicles from "./Vehicles";
import "./style.scss";

const PathnamePiker = {
  Models: ({ search }) => {
    const manufacturer = get(search, "manufacturer", {});
    const manufacturerBean = get(search, "manufacturerBean", {});

    return (
      <Models
        manufacturer={manufacturer}
        manufacturerBean={manufacturerBean}
      ></Models>
    );
  },
  Vehicles: ({ search }) => {
    const {
      model,
      car,
      manufacturer,
      manufacturerBean,
      modelBean,
      motorizationBean,
    } = search;

    return (
      <Vehicles
        model={model}
        car={motorizationBean}
        manufacturer={manufacturer}
        manufacturerBean={manufacturerBean}
        modelBean={modelBean}
      ></Vehicles>
    );
  },

  AssemblyGroups: ({ search }) => {
    const {
      linkingTarget: car,
      manufacturerBean,
      modelBean,
      motorizationBean,
    } = search;
    console.log("AssemblyGroups search", search);
    return (
      <AssemblyGroups
        car={car}
        motorizationBean={motorizationBean}
        manufacturerBean={manufacturerBean}
        modelBean={modelBean}
      ></AssemblyGroups>
    );
  },
  Articles: ({ search }) => {
    const {
      assemblyGroup,
      name,
      manufacturerBean,
      modelBean,
      motorizationBean,
    } = search;
    const car = get(search, "car", {});
    // console.log("car", car);
    // console.log("motorizationBean", motorizationBean);
    return search.name && search.car && search.assemblyGroup ? (
      <Articles
        name={name}
        car={motorizationBean}
        assemblyGroup={assemblyGroup}
      ></Articles>
    ) : null;
  },
};
export default (WrappedComponent) => (props) => {
  const {
    tab: {
      screen,
      props: { search },
    },
  } = props;
  console.log("AssemblyGroups search screen", screen);
  const Path =
    PathnamePiker[screen] ||
    function () {
      return null;
    };

  return (
    <Fragment>
      <div className={"path__container__global container"}>
        <Path search={search}></Path>
      </div>
      <WrappedComponent {...props} />
    </Fragment>
  );
};
