import PrivateRoute from "../../shared/authorization/PrivateRoute";
import { Customer as Layout } from "../../shared/components/Layout";
import Pusher from "./../../shared/components/Pusher";
import Assets from "./Assets";
import Cart from "./Cart";
import Catalog from "./Catalog";
import Complaints from "./Complaints";
import ContactUs from "./ContactUs";
import Entrees from "./Entrees";

import Home from "./Home";
import Invoices from "./Invoices";
import MesClients from "./MesClients";
import Orders from "./Orders";
import Profile from "./Profile";
import Promotions from "./Promotions";
import Recherches from "./Recherches";
import Retours from "./Retours";

import WebCataogue from "./WebCatalogue";

import Achats from "./Achats";
import Statement from "./Statement";
import TermsAndConditions from "./TermsAndConditions";
import WishList from "./WishList";
const routes = [
  ...Home,
  ...Catalog,
  ...Orders,
  ...Invoices,
  ...Achats,
  ...Cart,
  ...Assets,
  ...WebCataogue,
  // ...Claims,
  ...Entrees,
  ...Complaints,
  ...Statement,
  ...Profile,
  ...WishList,
  ...Promotions,
  ...Retours,
  ...MesClients,
  ...Recherches,

  {
    path: "/contact-us",
    exact: true,
    component: ContactUs,
  },
  {
    path: "/terms-conditions",
    exact: true,
    component: TermsAndConditions,
  },
];

export default ({ verticalProps, middleProps }) =>
  routes.map(({ component: Component, ...rest }) => ({
    component: () =>
      PrivateRoute({
        component: Layout({
          component: Pusher(Component),
          verticalProps,
          middleProps,
        }),
      }),
    ...rest,
  }));
