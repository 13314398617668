import { Modal } from "antd";
import React from "react";

export default ({ selectedProduct, setSelectedProduct }) => {
  return (
    <Modal
      title="Produits"
      visible={selectedProduct}
      width={800}
      onOk={() => setSelectedProduct(false)}
      cancelButtonProps={{ style: { display: "none" } }}
      onCancel={() => setSelectedProduct(false)}
    >
      <p>
        L'offre de Produits sur le Site s'entend dans la limite des stocks
        disponibles. Les indications sur la disponibilité des produits sont
        fournies par Autoplus au moment de la validation de la commande par le
        client.
      </p>
      <p>
        Le Client a connaissance du fait que la disponibilité des Produits est
        affichée à titre informatif et que malgré la fréquence de mise à jour
        des stocks, il peut exister un écart entre l'information fournie au
        consommateur et le stock réel de Autoplus.
      </p>
      <p>
        En cas de rupture de stock, Autoplus contactera le Client par sms ou par
        courrier électronique pour modifier le contenu de la commande ou
        procéder à son annulation totale ou partielle au choix du Client.
      </p>
    </Modal>
  );
};
