import gql from "graphql-tag";

export const WISH_LIST_ITEMS = gql`
  query WishListItems(
    $where: WishListItemWhereInput
    $skip: Int
    $like: WishListItemWhereInput
    $take: Int
    $gt: WishListItemWhereInput
    $lt: WishListItemWhereInput
  ) {
    wishListItems(
      where: $where
      skip: $skip
      take: $take
      like: $like
      gt: $gt
      lt: $lt
    ) {
      data {
        id

        price
        createdDate
        item {
          id
          code
          prices {
            id
            value
            updatedDate
          }
          thumbnail
          discount {
            id
            discountLines {
              id
              min
              max
              startDate
              endDate
              value
              updatedDate
              account {
                title
                id
              }
            }
          }
        }

        description
        wishList {
          id
          account {
            id
          }
        }
        quantity
        image
        deleted
      }
      count
    }
  }
`;

export const WISH_LIST_ITEMS_COUNT = gql`
  query WishListItemsCount {
    wishListItemsCount
  }
`;

export default {
  WISH_LIST_ITEMS,
};
