import { Modal } from "antd";
import React from "react";

export default ({ selectedReplacement, setSelectedReplacement }) => {
  return (
    <Modal
      className="form-modal"
      title="Pièce de rechange"
      visible={selectedReplacement}
      width={800}
      onOk={() => setSelectedReplacement(false)}
      cancelButtonProps={{ style: { display: "none" } }}
      onCancel={() => setSelectedReplacement(false)}
    >
      <p>
        Si vous avez des demandes d'informations sur des pièces de rechanges,
        vous avez la possibilité de joindre votre commercial ou notre Service
        client, du lundi au vendredi de 9h à 19h et le samedi de 9h à 13h au
        (+212) <i style={{ fontWeight: "bold" }}>05 22 61 51 51 </i>(Service
        gratuit + prix appel) ou encore par mail à l'adresse:
        <i style={{ fontWeight: "bold", color: "blue" }}>
          clients@maghrebaccessoires.com
        </i>
      </p>
    </Modal>
  );
};
