import { Icon, Tabs } from "antd";
import React from "react";
import { Col, Row } from "react-bootstrap";
import Heading from "../../../shared/components/stories/Heading";
import Claims from "./Claims";
import Map from "./Map";
import SocialNetwork from "./SocialNetwork";
import TechnicalHelp from "./TechnicalHelp";
import Values from "./Values";

const { TabPane } = Tabs;

const Contact = () => {
  return (
    <div className="section full contactPage">
      <div className="div-vertical" style={{ width: "100%" }}>
        <div className="titre-div-horizontal">
          <Heading title={"Nos coordonnées"} />
        </div>
        <Row
          gutter={[24, 16]}
          className=" p-top-40 p-bottom-20 p-left-20 p-right-20"
        >
          <Col xs={24} sm={24} md={4} lg={4} xl={4}>
            <div className="contactCard">
              <div className="contactCard_head">
                <Icon
                  type="phone"
                  style={{ fontSize: "50px" }}
                  theme="outlined"
                />
              </div>
              <div className="contactCard_body">
                <p>
                  Tél :
                  <span>
                    (+212) 0522-328117 / (+212) 0522-325418 <br />
                    (+212) 0522-516161 <br />
                  </span>
                  Whatsapp : (+212) 771-525252 <br />
                  FAX (+212) 0522-328045
                </p>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={4}>
            <div className="contactCard">
              <div className="contactCard_head">
                <Icon
                  type="environment"
                  style={{ fontSize: "50px" }}
                  theme="outlined"
                />
              </div>
              <div className="contactCard_body">
                <p>
                  {" "}
                  BOULEVARD AHL LAGHLAM, (ANCIEN ALMA PACK), SIDI BERNOUSSI,
                  ZENATA, 20400, CASABLANCA
                </p>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={4}>
            <div className="contactCard">
              <div className="contactCard_head">
                <Icon
                  type="mail"
                  style={{ fontSize: "50px" }}
                  theme="outlined"
                />
              </div>
              <div className="contactCard_body">
                <p>clients@maghrebaccessoires.com</p>
              </div>
            </div>
          </Col>
        </Row>

        <div className="p-top-20 ">
          <div className="titre-div-horizontal">
            <Heading title={"Aide"} />
          </div>
          <div className="modal_details p-top-20 p-bottom-20 p-left-20 p-right-20">
            <Row gutter={[24, 16]}>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Claims />
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <SocialNetwork />
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <TechnicalHelp />
              </Col>

              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Values />
              </Col>
            </Row>
          </div>
        </div>
        <div className="p-top-20 ">
          <div className="titre-div-horizontal">
            <Heading title={"Localisation"} />
          </div>

          <Tabs defaultActiveKey="2" className="text-center">
            <TabPane
              tab={
                <span>
                  <Icon
                    type="menu"
                    style={{ fontSize: "20px" }}
                    theme="outlined"
                  />
                  Mode List
                </span>
              }
              key="1"
            >
              <div
                className="div-horizontal wrapped p-left-20 p-right-20 p-top-20 m-bottom-20"
                style={{ alignItems: "stretch" }}
              >
                <Col
                  className="p-top-10 m-bottom-10"
                  xs={24}
                  sm={24}
                  md={6}
                  lg={4}
                >
                  <div className="contactList">
                    <div className="contactInfo_info">
                      <h3>AIT MELLOUL</h3>
                      <p>Bloc 1 N°4 Bis Lot Ait Said Ait Melloul 80150</p>
                    </div>

                    <div className="contactInfo_hours">
                      <h3>Horaire été: </h3>
                      <p>Lundi - vendredi : 09H00-13H00 15H00-19H00</p>
                      <p>Samedi: 09H00-13H00</p>

                      <h3>Horaire d’hiver: </h3>
                      <p>Lundi - vendredi : 08H30 12H30 14H30 - 18H30</p>
                      <p>Samedi: 08H30 12H30</p>
                    </div>
                  </div>
                </Col>

                <Col
                  className="p-top-10 m-bottom-10"
                  xs={24}
                  sm={24}
                  md={6}
                  lg={4}
                >
                  <div className="contactList">
                    <div className="contactInfo_info">
                      <h3>AGADIR</h3>
                      <p>
                        Avenue Cadi Ayyad N° 6 (rdc) Résidence Al Majd
                        Lotissement Amsernat, 80000 Agadir
                      </p>
                    </div>
                    <div className="contactInfo_hours">
                      <h3>Horaire été: </h3>
                      <p>Lundi - vendredi : 09H00-13H00 15H00-19H00</p>
                      <p>Samedi: 09H00-13H00</p>

                      <h3>Horaire d’hiver: </h3>
                      <p>Lundi - vendredi : 08H30 12H30 14H30 - 18H30</p>
                      <p>Samedi: 08H30 12H30</p>
                    </div>
                  </div>
                </Col>

                <Col
                  className="p-top-10 m-bottom-10"
                  xs={24}
                  sm={24}
                  md={6}
                  lg={4}
                >
                  <div className="contactList">
                    <div className="contactInfo_info">
                      <h3>MARRAKECH</h3>
                      <p>11, Bvd Mansour Eddahbi , 40000 Marrakech</p>
                    </div>
                    <div className="contactInfo_hours">
                      <h3>Horaire été: </h3>
                      <p>Lundi - vendredi : 09H00-13H00 15H00-19H00</p>
                      <p>Samedi: 09H00-13H00</p>

                      <h3>Horaire d’hiver: </h3>
                      <p>Lundi - vendredi : 08H30 12H30 14H30 - 18H30</p>
                      <p>Samedi: 08H30 12H30</p>
                    </div>
                  </div>
                </Col>

                <Col
                  className="p-top-10 m-bottom-10"
                  xs={24}
                  sm={24}
                  md={6}
                  lg={4}
                >
                  <div className="contactList">
                    <div className="contactInfo_info">
                      <h3>TANGER</h3>
                      <p>30, Bvd d’Anfa - Hay Souan - 90000 Tanger</p>
                    </div>
                    <div className="contactInfo_hours">
                      <h3>Horaire été: </h3>
                      <p>Lundi - vendredi : 09H00-13H00 15H00-19H00</p>
                      <p>Samedi: 09H00-13H00</p>

                      <h3>Horaire d’hiver: </h3>
                      <p>Lundi - vendredi : 08H30 12H30 14H30 - 18H30</p>
                      <p>Samedi: 08H30 12H30</p>
                    </div>
                  </div>
                </Col>

                <Col
                  className="p-top-10 m-bottom-10"
                  xs={24}
                  sm={24}
                  md={6}
                  lg={4}
                >
                  <div className="contactList">
                    <div className="contactInfo_info">
                      <h3>RABAT</h3>
                      <p>Quartier Océan - 19, rue Leningrad</p>
                    </div>
                    <div className="contactInfo_hours">
                      <h3>Horaire été: </h3>
                      <p>Lundi - vendredi : 09H00-13H00 15H00-19H00</p>
                      <p>Samedi: 09H00-13H00</p>

                      <h3>Horaire d’hiver: </h3>
                      <p>Lundi - vendredi : 08H30 12H30 14H30 - 18H30</p>
                      <p>Samedi: 08H30 12H30</p>
                    </div>
                  </div>
                </Col>

                <Col
                  className="p-top-10 m-bottom-10"
                  xs={24}
                  sm={24}
                  md={6}
                  lg={4}
                >
                  <div className="contactList">
                    <div className="contactInfo_info">
                      <h3>FES</h3>
                      <p>
                        Rés. Mariane - 1, rue Derrar Ibn Azour Angle Avenue des
                        FAR (Ville Nouvelle)
                      </p>
                    </div>
                    <div className="contactInfo_hours">
                      <h3>Horaire été: </h3>
                      <p>Lundi - vendredi : 09H00-13H00 15H00-19H00</p>
                      <p>Samedi: 09H00-13H00</p>

                      <h3>Horaire d’hiver: </h3>
                      <p>Lundi - vendredi : 08H30 12H30 14H30 - 18H30</p>
                      <p>Samedi: 08H30 12H30</p>
                    </div>
                  </div>
                </Col>

                <Col
                  className="p-top-10 m-bottom-10"
                  xs={24}
                  sm={24}
                  md={6}
                  lg={4}
                >
                  <div className="contactList">
                    <div className="contactInfo_info">
                      <h3>MEKNES</h3>
                      <p>
                        27, rue Abdelmoumen Al Mouahidi - Résidence Khozama -
                        Mèknes El Menzeh
                      </p>
                    </div>
                    <div className="contactInfo_hours">
                      <h3>Horaire été: </h3>
                      <p>Lundi - vendredi : 09H00-13H00 15H00-19H00</p>
                      <p>Samedi: 09H00-13H00</p>

                      <h3>Horaire d’hiver: </h3>
                      <p>Lundi - vendredi : 08H30 12H30 14H30 - 18H30</p>
                      <p>Samedi: 08H30 12H30</p>
                    </div>
                  </div>
                </Col>

                <Col
                  className="p-top-10 m-bottom-10"
                  xs={24}
                  sm={24}
                  md={6}
                  lg={4}
                >
                  <div className="contactList">
                    <div className="contactInfo_info">
                      <h3>OUJDA</h3>
                      <p>8 Bis, rue El Ghazaouet, Oujda</p>
                    </div>
                    <div className="contactInfo_hours">
                      <h3>Horaire été: </h3>
                      <p>Lundi - vendredi : 09H00-13H00 15H00-19H00</p>
                      <p>Samedi: 09H00-13H00</p>

                      <h3>Horaire d’hiver: </h3>
                      <p>Lundi - vendredi : 08H30 12H30 14H30 - 18H30</p>
                      <p>Samedi: 08H30 12H30</p>
                    </div>
                  </div>
                </Col>

                <Col
                  className="p-top-10 m-bottom-10"
                  xs={24}
                  sm={24}
                  md={6}
                  lg={4}
                >
                  <div className="contactList">
                    <div className="contactInfo_info">
                      <h3>BERRECHID</h3>
                      <p>RUE EL MOURABITINE Z.I de BERRECHID 26100</p>
                    </div>
                    <div className="contactInfo_hours">
                      <h3>Horaire été: </h3>
                      <p>Lundi - vendredi : 09H00-13H00 15H00-19H00</p>
                      <p>Samedi: 09H00-13H00</p>

                      <h3>Horaire d’hiver: </h3>
                      <p>Lundi - vendredi : 08H30 12H30 14H30 - 18H30</p>
                      <p>Samedi: 08H30 12H30</p>
                    </div>
                  </div>
                </Col>

                <Col
                  className="p-top-10 m-bottom-10"
                  xs={24}
                  sm={24}
                  md={6}
                  lg={4}
                >
                  <div className="contactList">
                    <div className="contactInfo_info">
                      <h3>BERNOUSSI</h3>
                      <p>Bd AHL LOGHLAM - Sidi bernoussi Zenata 20400</p>
                    </div>
                    <div className="contactInfo_hours">
                      <h3>Horaire été: </h3>
                      <p>Lundi - vendredi : 09H00-13H00 15H00-19H00</p>
                      <p>Samedi: 09H00-13H00</p>

                      <h3>Horaire d’hiver: </h3>
                      <p>Lundi - vendredi : 08H30 12H30 14H30 - 18H30</p>
                      <p>Samedi: 08H30 12H30</p>
                    </div>
                  </div>
                </Col>

                <Col
                  className="p-top-10 m-bottom-10"
                  xs={24}
                  sm={24}
                  md={6}
                  lg={4}
                >
                  <div className="contactList">
                    <div className="contactInfo_info">
                      <h3>LAMBERT</h3>
                      <p>Bd AHL LOGHLAM - Sidi bernoussi Zenata 20400</p>
                    </div>
                    <div className="contactInfo_hours">
                      <h3>Horaire été: </h3>
                      <p>Lundi - vendredi : 09H00-13H00 15H00-19H00</p>
                      <p>Samedi: 09H00-13H00</p>

                      <h3>Horaire d’hiver: </h3>
                      <p>Lundi - vendredi : 08H30 12H30 14H30 - 18H30</p>
                      <p>Samedi: 08H30 12H30</p>
                    </div>
                  </div>
                </Col>

                <Col
                  className="p-top-10 m-bottom-10"
                  xs={24}
                  sm={24}
                  md={6}
                  lg={4}
                >
                  <div className="contactList">
                    <div className="contactInfo_info">
                      <h3>GAP</h3>
                      <p>
                        BD khouribga, 7 rue maarakat ait abdellah, Casablanca
                        20250
                      </p>
                    </div>
                    <div className="contactInfo_hours">
                      <h3>Horaire été: </h3>
                      <p>Lundi - vendredi : 09H00-13H00 15H00-19H00</p>
                      <p>Samedi: 09H00-13H00</p>

                      <h3>Horaire d’hiver: </h3>
                      <p>Lundi - vendredi : 08H30 12H30 14H30 - 18H30</p>
                      <p>Samedi: 08H30 12H30</p>
                    </div>
                  </div>
                </Col>

                <Col
                  className="p-top-10 m-bottom-10"
                  xs={24}
                  sm={24}
                  md={6}
                  lg={4}
                >
                  <div className="contactList">
                    <div className="contactInfo_info">
                      <h3>LAAYOUNE</h3>
                      <p>Avenue AL HIZAM a coté de Dépôt AL ATLAS</p>
                    </div>
                    <div className="contactInfo_hours">
                      <h3>Horaire : </h3>
                      <p>09H30-19H00</p>
                    </div>
                  </div>
                </Col>
              </div>
            </TabPane>
            <TabPane
              tab={
                <span>
                  <Icon
                    type="environment"
                    style={{ fontSize: "20px" }}
                    theme="outlined"
                  />
                  Mode map
                </span>
              }
              key="2"
            >
              <Map />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Contact;
